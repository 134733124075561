<template>
  <div class="conditions">
    <div class="conditions-header">
      <h2 class="__title">
        {{ i18n.title }}
      </h2>
      <p class="__desc">
        <span
          class="__desc-row"
          v-html="i18n.description[0]"
        />
        <span v-html="i18n.description[1]" />
      </p>
    </div>
    <div class="conditions-container">
      <dl class="conditions-description">
        <dt class="__term">
          {{ i18n.list[0].term }}
        </dt>
        <dd class="__definition">
          {{ i18n.list[0].definition }}
        </dd>
      </dl>
      <dl class="conditions-description">
        <dt class="__term">
          {{ i18n.list[1].term }}
        </dt>
        <dd class="__definition">
          {{ i18n.list[1].definition }}
        </dd>
      </dl>
      <dl class="conditions-description _big">
        <dt class="__term">
          {{ i18n.list[2].term }}
        </dt>
        <dd class="__definition">
          {{ i18n.list[2].definition }}
        </dd>
      </dl>
    </div>
    <div
      :class="[
        'condition-more',
        {
          _open: isOpenMore,
        },
      ]"
      @click="onClickMore"
    >
      <div class="condition-more__btn">
        {{ i18n.more.title }} <Icon type="arrow-right" />
      </div>
      <p class="condition-more__detailed">
        {{ i18n.more.detailed }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "Conditions",
  props: {
    i18n: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      isOpenMore: false,
    };
  },
  methods: {
    onClickMore() {
      this.isOpenMore = !this.isOpenMore;
    },
  },
};
</script>

<style scoped lang="scss" src="./conditions.scss" />
